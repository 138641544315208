import React from "react"
import * as styles from '../styles.module.scss'

/**
 * Intended to be used inside all pathway thumbails to display user's progress
 * If percentComplete prop is equal to 1-99 (integer or string), then the progress bar is displayed
 */

const PathwayProgressBar = ({percentComplete}) => {
  const percentProgress =
    parseInt(percentComplete) < 100 && parseInt(percentComplete) > 0
      ? parseInt(percentComplete)
      : 0

  const barDisplay = percentProgress === 0 ? "none" : "block"
  const percentRemaining = percentProgress > 0 ? 100 - percentProgress : 0
  const barBackground =
    percentRemaining > percentProgress
      ? `linear-gradient(to left, ${styles.whiteColor} ${percentRemaining}%, ${styles.goldColor} ${percentProgress}%)`
      : `linear-gradient(to right, ${styles.goldColor} ${percentProgress}%, ${styles.whiteColor} ${percentProgress}%)`

  return (
    <div
      className="progress-bar"
      style={{
        backgroundImage: barBackground,
        display: barDisplay,
      }}
    ></div>
  )
}

export default PathwayProgressBar
