import React from "react"
import { Link } from "gatsby"
import { useProgressContext } from "../../progressWrapper"
import ContentThumbnail from "../global-components/thumbnails/content-thumbnail"

const PathwayCard = ({cardKey, linkTo, linkState, backgroundImage, percentComplete, isDiseaseSpecific, isDisabled}) => {

    return (
        <div className={"pathways__card " + (isDisabled ? "disabled" : "")} key={cardKey}>
            <Link
                to={linkTo}
                state={linkState}
            >
                <ContentThumbnail
                    backgroundImage={backgroundImage}
                    percentComplete={percentComplete}
                    isDiseaseSpecific={isDiseaseSpecific}
                />
            </Link>
        </div>
    )
}

// Takes specific pathways based on category from index.js
// Renders PathwayCard components based on category and status
// of the overview object
const PathwaysGrid = ({pathways, interstitial}) => {

    const [progressData, progressLoading, setProgressLoading] = useProgressContext();

    const getPathwayProgress = (pathwayID) => {
        if (progressData) {
            const pathwayProgress = progressData.progress.filter(x => x.pathwayPostId == pathwayID)
            if (pathwayProgress[0]) {
                if(interstitial) {
                    return pathwayProgress[0].pathwayPercentComplete
                }
            }
        }
        return 0
    }

    const getStepProgress = (pathwayId, stepName) =>  {
        if (progressData) {
            const pathwayProgress = progressData.progress.filter(x => x.pathwayPostId == pathwayId);
            if (pathwayProgress[0]) {
                const fieldProgress = pathwayProgress[0].pathwayFields.filter(x => x.name == stepName);
                
                return fieldProgress[0].pathwayFieldPercentComplete
            }
        }
        return 0
    }

    //retuns boolean describing if a pathway contains module content
    const pathwayContainsModuleContent = (pathway) => {
        return !!Object.entries(pathway.post.pathwayFields).filter((field) => field[1].stepBlock).length
    }

    // Returns PathwayCards for pathways that have Overviews
    const renderOverview = () => {

        const pathwayCategory = pathways[0].post.pathwayCategories.nodes[0].name.toLowerCase()
         return (
            pathways.map((pathway, index) => {
                return (
                    <PathwayCard
                      key={index}
                      cardKey={pathway.post.id}
                      linkTo={pathwayCategory + pathway.post.uri}
                      backgroundImage={pathway.post.featuredImage.node}
                      percentComplete={ getPathwayProgress(pathway.post.databaseId) }
                      isDiseaseSpecific={true}
                      isDisabled={!pathwayContainsModuleContent(pathway)}
                    />
                )
            })
        )
    }

    // Returns PathwayCards for pathways without Overviews
    // Ex: The Master Pathway

    const renderSteps = () => {

        return (
            pathways.map((pathway) => {

                let steps = Object.entries(pathway.post.pathwayFields)

                return (
                steps.map((step, index) => {
                    if (step[1].stepImage !== null) {
                        return (
                            <PathwayCard
                                key={index}
                                cardKey={step[0].toString()}
                                linkTo={pathway.post.uri + step[1].fieldGroupName.replace(/_/g, "-")}
                                linkState={{step: step[0].toString()}}
                                backgroundImage={step[1].stepImage}
                                percentComplete={getStepProgress(pathway.post.databaseId, [step[0]])}
                                isDiseaseSpecific={false}
                                isDisabled={!pathwayContainsModuleContent(pathway)}
                            />
                        )
                    }
                })
                )
            })
       )
    }

    // Checks for the existence of the overview excerpt
    // returns result of appropriate render function
    const renderPathway = () => {
        return (interstitial ? renderOverview() : renderSteps());
    }

    return (
        <section className="pathways-grid">
          { renderPathway() }
        </section>
    )
}

export default PathwaysGrid
