import React, { useState, useEffect } from 'react'
import SearchResults from '../search-components/searchResults'
import CloseIcon from "../../../content/assets/close-icon-alt.svg"

// **************************************************************
//  - displays notice to allow user to pick up where they left off
//  - makes use the display block used in search results
//  - user has abilty to dismiss notice (clearing local storage)
//  - accepts param of headerColorData {obj}
// **************************************************************

const LastLocation = ({ headerColorData }) => {
  const headerColors = headerColorData?.stepColors?.acfOptionsCompassusAppSettings.globalOptions.stepColors
  const [show, setShow] = useState()

  const handleDismiss = () => {
    setShow(false)

    // skip errors when compiling on server/window is not avialable
    if (typeof window !== 'undefined') {
      localStorage.removeItem('compassus_lastLocation')
    }
  }
 

  let storedPath = ''

  // skip errors when compiling on server/window is not avialable
  if (typeof window !== 'undefined') {
    storedPath = localStorage.getItem('compassus_lastLocation') ? localStorage.getItem('compassus_lastLocation') : ''
  } 

  const lastLocationPath = storedPath ? storedPath.split('?')[0] : ''
  const pathwayTitle = lastLocationPath.split('/')[1] ? lastLocationPath.split('/')[1].replaceAll('-', ' ') : ''
  const stepTitle = lastLocationPath.split('/')[2] ? lastLocationPath.split('/')[2].replaceAll('-', ' ') : ''
  const moduleTitle = lastLocationPath.split('/')[3] ? lastLocationPath.split('/')[3].replaceAll('-', ' ').replaceAll(/[0-9]/g, "") : ''
  const slideNumber = storedPath.split('slide-number=')[1] ? storedPath.split('slide-number=')[1] : ''
  const slideParam = slideNumber ? '?slide-number=' + slideNumber : ''

  const lastLocationData = [{
    'path': `${lastLocationPath}${slideParam}`,
    'pathwayTitle': `${pathwayTitle}`,
    'stepTitle': `${stepTitle}`,
    'moduleTitle': `${moduleTitle}`,
    'slide': [],
    'slideNumber': `${slideNumber}`
  }]

  useEffect(() => {
    if (storedPath) { setShow(true) } 
  }, []);   
   
  return (
    <div className={'last-location content_card' + (show ? ' active' : '')}>
      <div 
        className='dismiss'
        onClick={() => handleDismiss()}
      >
        <CloseIcon />
      </div>

      <h3>Pick up where you left off:</h3>
      <SearchResults searchTerm='default' searchResultData={lastLocationData} headerColors={headerColors}/>
    </div>
  )
}

export default LastLocation