import React from "react"
import CheckmarkIcon from "../../../content/assets/checkmark-icon.svg"

/**
 * Intended to display user's completion based on "precentComplete" prop
 * If the percentComplete prop is equal to 100 (integer or string), then the checkmark will be displayed
 */

const CompletionCheckmark = ({percentComplete}) => {
  const checkmark = parseInt(percentComplete) === 100 ? (
    <div className="completion-checkmark">
      <CheckmarkIcon />
    </div>
  ) : (
    ""
  )
  return (
    <>
      {checkmark}
    </>
  )
}

export default CompletionCheckmark
