import React from "react"

import CompletionCheckmark from "../completioncheckmark"
import PathwayProgressBar from "../pathwayprogressbar"

const ContentThumbnail = ({backgroundImage, percentComplete, isDiseaseSpecific}) => { 
    // const contentDisabled = disabled ? "disabled" : ""
    return (
      <div
        className={
          "pathways-grid__card__wrapper "
        }
      >
        <div
          className={"pathways-grid__card__thumbnail " + (isDiseaseSpecific ? "pathways-grid__card__thumbnail--disease" : "")}
          style={{ backgroundImage: "url(" + backgroundImage.publicUrl + ")" }}
        >
          <PathwayProgressBar percentComplete={percentComplete} />
          <CompletionCheckmark percentComplete={percentComplete} />
        </div>
      </div>
    )
}

export default ContentThumbnail;