import React, {useContext} from "react"
import { graphql } from "gatsby"

import { ProfileContext } from "../profileWrapper";
import PathwayDashboard from "../components/page-components/pathwaydashboard";

const browser = typeof window !== "undefined" && window;

const HomePage = ({data}) => {
  // Returns an array of pathways matching a category string
  // NOTE: Pathways without an "overview" like the master pathway can only have one pathway
  //       per category
  //

  const profile = useContext(ProfileContext);

  return (
    browser && (
      <>
        <PathwayDashboard data={data} />
      </>
    )
  )
}

export default HomePage

//this query will need to be refined based on authentication. Modules and other fields not required on this page.
export const pageQuery = graphql`
query getUserPathways {
  pathways: allWpPathway {
    edges {
      post: node {
        id
        databaseId
        uri
        title
        featuredImage {
          node {
            sourceUrl
            publicUrl
          }
        }
        pathwayCategories {
          nodes {
            name
          }
        }
        pathwayFields {
          overview {
            fieldGroupName
            stepImage {
              altText
              sourceUrl
              publicUrl
              uri
            }
            stepBlock {
              ... on WpPathway_Pathwayfields_Overview_stepBlock {
                header
                modules {
                  module {
                    ... on WpModule {
                      id
                      databaseId
                      title
                      uri
                    }
                  }
                }
              }
            }
          }
          rightMedsRightTime {
            fieldGroupName
            stepImage {
              altText
              sourceUrl
              publicUrl
              uri
            }
            stepBlock {
              ... on WpPathway_Pathwayfields_RightMedsRightTime_stepBlock {
                header
                modules {
                  module {
                    ... on WpModule {
                      id
                      databaseId
                      title
                      uri
                    }
                  }
                }
              }
            }
          }
          totalPainManagement {
            fieldGroupName
            stepImage {
              altText
              sourceUrl
              publicUrl
              uri
            }
            stepBlock {
              ... on WpPathway_Pathwayfields_TotalPainManagement_stepBlock {
                header
                modules {
                  module {
                    ... on WpModule {
                      id
                      databaseId
                      title
                      uri
                    }
                  }
                }
              }
            }
          }
          symptomManagement {
            fieldGroupName
            stepImage {
              altText
              sourceUrl
              publicUrl
              uri
            }
            stepBlock {
              ... on WpPathway_Pathwayfields_SymptomManagement_stepBlock {
                header
                modules {
                  module {
                    ... on WpModule {
                      id
                      databaseId
                      title
                      uri
                    }
                  }
                }
              }
            }
          }
          greatestConcern {
            fieldGroupName
            stepImage {
              altText
              sourceUrl
              publicUrl
              uri
            }
            stepBlock {
              ... on WpPathway_Pathwayfields_GreatestConcern_stepBlock {
                header
                modules {
                  module {
                    ... on WpModule {
                      id
                      databaseId
                      title
                      uri
                    }
                  }
                }
              }
            }
          }
          safetyAndAutonomy {
            fieldGroupName
            stepImage {
              altText
              sourceUrl
              publicUrl
              uri
            }
            stepBlock {
              ... on WpPathway_Pathwayfields_SafetyAndAutonomy_stepBlock {
                header
                modules {
                  module {
                    ... on WpModule {
                      id
                      databaseId
                      title
                      uri
                    }
                  }
                }
              }
            }
          }
          qualityOfLife {
            fieldGroupName
            stepImage {
              altText
              sourceUrl
              publicUrl
              uri
            }
            stepBlock {
              ... on WpPathway_Pathwayfields_QualityOfLife_stepBlock {
                header
                modules {
                  module {
                    ... on WpModule {
                      id
                      databaseId
                      title
                      uri
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  stepColors: wp {
    acfOptionsCompassusAppSettings {
      globalOptions {
        stepColors {
          totalPainManagement
          symptomManagement
          safetyAndAutonomy
          rightMedsRightTime
          qualityOfLife
          overview
          greatestConcern
        }
      }
    }
  }
}
`
