import React from "react"

import Layout from "../../components/layout"
import Header from "../../components/page-components/header"
import Seo from "../../components/seo"
import NavBar from "../../components/global-components/navbar"
import PathwaysGrid from "../page-components/pathwaygrid"
import LastLocation from "./lastLocation"

const PathwayDashboard = ({ data, progressData }) => {
  // Returns an array of pathways matching a category string
  // NOTE: Pathways without an "overview" like the master pathway can only have one pathway
  //       per category
  //
  const getPathway = (category) => {
    const pathways = data.pathways.edges;

    return pathways.filter((pathway) => {
      return pathway.post.pathwayCategories.nodes[0].name.toLowerCase() === category.toLowerCase()
    })

  }

  return (
    <>
        <Layout>
            <Header />
            <LastLocation headerColorData={ data }/>
            <Seo title="Compassus" />
            <section className="pathways">
              <h2>Foundations</h2>
              <PathwaysGrid pathways={ getPathway("Master")}  />
              <h2>Pathways</h2>
              <PathwaysGrid pathways={ getPathway("Disease-Specific")} interstitial={true} />
            </section>
        </Layout>
        <NavBar/>
    </>
  )
}

export default PathwayDashboard
